<template> 
  <div class="container" :style="{'background-image': 'url(' + detailImage + ')'}">
    <div class="scene">{{ sceneName }}</div>
    <!-- <img class="footer" src="https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4ae5-54a2-ebdc-4723-4dcf98bb790b?x-oss-process=style/origin" alt=""> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      sceneName: "",
      detailImage: "",
    }
  },
  created() {
    const { sceneName, detailImage } = this.$route.query;
    this.sceneName = sceneName;
    this.detailImage = detailImage;
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/global.scss";
$multipleCount: 1.7857;

.container {
  position: relative;
  width: 100%;
  height: 80vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  > .scene {
    position: absolute;
    min-width: px2rem(136 * $multipleCount);
    height: px2rem(52 * $multipleCount);
    color: #1ffd91;
    margin: px2rem(16 * $multipleCount);
    padding: px2rem(6 * $multipleCount) px2rem(14 * $multipleCount) px2rem(8 * $multipleCount) px2rem(24 * $multipleCount);
    font-size: px2rem(20 * $multipleCount);
    font-family: "Alibaba PuHuiTi Heavy", Alibaba PuHuiTi;
    background: url("https://assets.cumen.fun/upload/017cdaf1-c5fa-788e-eea2-d0ee249f902b/018a4ae5-54a3-9ab0-df46-79fd0bef6d49?x-oss-process=style/origin") repeat-x left top / 100% 100%;
    box-sizing: border-box;
  }

  > .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: px2rem(96 * $multipleCount);
  }
}
</style>
